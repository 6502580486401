import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container } from 'styled-bootstrap-grid'
import LogoIcon from '../../images/logo.svg'
import Menu from '../../images/menu.svg'
import media from '../../styles/media'

const StyledDiv = styled.div`
  width: 100%;
  padding: 0 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  max-width: 16.4rem;
  ${media.up.sm`
  max-width: 20.5rem;
  `}
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  min-height: 8rem;
  ${media.up.sm`
    min-height: 12.8rem;
  `}
`

const MenuButton = styled.img`
  cursor: pointer;
  transition: 0.3s opacity;
  opacity: ${props => (props.visible ? 1 : 0)};
  max-width: 4.8rem;
  ${media.up.sm`
    max-width: 5.6rem;
  `}
`

const Navigation = props => (
  <Wrapper>
    <StyledDiv>
      <a href="/">
        <Logo src={LogoIcon} alt="logo" />
      </a>
      <MenuButton
        visible={!props.navIsOpen}
        src={Menu}
        alt="menu"
        onClick={props.handleOpen}
      />
    </StyledDiv>
  </Wrapper>
)

export default Navigation
