/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import media from '../styles/media'
import MenuComp from './Menu'

import Header from './Header'

const Page = styled.div`
  background-color: white;
  position: relative;
  transition: 0.3s transform;
  transform: ${props => (props.navIsOpen ? 'translateX(-100vw)' : 'none')};
  ${media.up.sm`
    transform: ${props => (props.navIsOpen ? 'translateX(-37.5rem)' : 'none')};
  `}
  box-shadow: 4px 0 8px 0 rgba(34,34,34,0.2);
`

const Menu = styled.div`
  opacity: ${props => (props.navIsOpen ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  ${media.up.sm`
    width: 37.5rem;
  `}
`

// const MenuItems = [
//   { name: 'Restschulden', url: 'URL' },
//   { name: 'Anschlussfinanzierung', url: 'URL' },
// ]

class Layout extends React.Component {
  state = {
    navIsOpen: false,
  }

  handleMenuOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      navIsOpen: true,
    }))
  }

  handleMenuClose = () => {
    this.setState(prevState => ({
      ...prevState,
      navIsOpen: false,
    }))
  }

  render() {
    const { children } = this.props
    const { navIsOpen } = this.state

    return (
      <>
        <Menu navIsOpen={navIsOpen}>
          <MenuComp handleMenuClose={this.handleMenuClose} />
        </Menu>
        <Page navIsOpen={navIsOpen}>
          <Header navIsOpen={navIsOpen} onMenuOpen={this.handleMenuOpen} />
          <div>
            <main>{children}</main>
          </div>
        </Page>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
