import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { space } from 'styled-system'
import Scrollchor from 'react-scrollchor'
import media from '../../styles/media'
import { Copy, Headline } from '../Text'
import arrowDown from '../../images/arrowdown.svg'
import Navigation from '../Navigation'

const StyledHeadline = styled(Headline)`
  max-width: 47.4rem;
  color: white;
  display: inline-block;
`

const StyledCopy = styled(Copy)`
  color: white;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.6rem;
  ${media.up.md`
  font-size: 1.8rem;
  line-height: 2.7rem;
  `}
`

const Spacer = styled.div`
  ${space}
`

const StyledDiv = styled.div`
  text-align: center;
`

const ArrowDown = styled.img`
  ${media.down.sm`
    max-width: 5.6rem;
  `}
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
`

const Header = ({ navIsOpen, onMenuOpen }) => (
  <div>
    <Navigation navIsOpen={navIsOpen} handleOpen={onMenuOpen} />
    <Wrapper>
      <Container>
        <StyledDiv>
          <Row>
            <Col col={12}>
              <Spacer pt={{ xxs: 4 }} />
              <StyledHeadline>
                Feiern Sie Ihren Schuldenfrei-Tag!
              </StyledHeadline>
            </Col>
          </Row>
          <Row>
            <Col col={12} sm={10} smOffset={1} md={8} mdOffset={2}>
              <StyledCopy as="div" mt={{ xxs: 4 }}>
                Schneller in die Schuldenfreiheit: Wir haben knapp 100.000
                Finanzierungen in ganz Deutschland geprüft und große zeitliche
                sowie finanzielle Sparpotenziale gefunden, auch in Ihrer Region.
                Überprüfen Sie es selbst, mit unserer interaktiven
                Deutschlandkarte.
              </StyledCopy>
            </Col>
          </Row>
          <Row>
            <Col>
              <Spacer mt={{ xxs: 4 }} />
              <Scrollchor to="#restschulden">
                <ArrowDown src={arrowDown} alt="arrowdown" />
              </Scrollchor>
              <Spacer pb={{ xxs: 3 }} />
            </Col>
          </Row>
        </StyledDiv>
      </Container>
    </Wrapper>
  </div>
)

export default Header
