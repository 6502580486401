import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import Scrollchor from 'react-scrollchor'
import PropTypes from 'prop-types'
import media from '../../styles/media'
import { MenuText } from '../Text'
import LogoIcon from '../../images/logo.svg'
import CloseIcon from '../../images/close.svg'

const Spacer = styled.div`
  ${space}
`

const Navigation = styled.div`
  min-height: 12.8rem;
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
  align-items: center;
  ${media.down.sm`
    min-height: 8rem;
  `}
`

const Close = styled.img`
  cursor: pointer;
  max-width: 4.8rem;
  ${media.up.sm`
    max-width: 5.6rem;
  `}
`

const Logo = styled.img`
  max-width: 16.4rem;
  ${media.up.sm`
  max-width: 20.5rem;
  `}
`

const MenuLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 3.2rem;
  padding: 0 3.2rem;
  overflow: scroll;
  li {
    &:not(:last-child) {
      border-bottom: 0.2rem solid #dddddd;
    }
    &:not(:first-child) {
      margin-top: 1.6rem;
    }
    padding-bottom: 1.6rem;
  }
`

const Wrapper = styled.div`
  height: 100vh;
  background-color: #f2f2f1;
  display: flex;
  flex-direction: column;
`

const menuItems = [
  { name: 'Sparpotenzial entdecken', id: 'restschulden' },
  { name: 'Über Anschlussfinanzierung', id: 'anschlussfinanzierung' },
  { name: 'FAQ', id: 'faq' },
  { name: 'Schuldenfrei-Tags-Rechner', id: 'tilgungsrechner' },
  { name: 'Persönliche Beratung', id: 'kundenberatung' },
  { name: 'Kundenerfahrungen', id: 'testimonials' },
  { name: 'Über Interhyp', id: 'interhyp' },
  { name: 'Newsletter', id: 'newsletter' },
]

const Menu = ({ handleMenuClose }) => (
  <Wrapper>
    <Navigation>
      <a href="/">
        <Logo src={LogoIcon} />
      </a>
      <Close src={CloseIcon} onClick={handleMenuClose} />
    </Navigation>
    <MenuLinks>
      {menuItems.map(item => (
        <li key={item.name} onClick={handleMenuClose}>
          <Scrollchor to={`#${item.id}`}>
            <MenuText>{item.name}</MenuText>
          </Scrollchor>
        </li>
      ))}
    </MenuLinks>
  </Wrapper>
)

Menu.propTypes = {
  MenuItems: PropTypes.array,
}

export default Menu
